import React, { useState, useEffect } from 'react';
import {useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";

const AutoQuoteForm = (props) => {
    const [years, setYears] = useState('');
    
    const history = useHistory();

    useEffect(async () => {
        try {
            const yearResponse = await fetch('https://www.carqueryapi.com/api/0.3/?callback=?&cmd=getYears', { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const yearRange = await yearResponse.json();
            var minYear = yearRange.min_year;
            var maxYear = yearRange.max_year;
            var arrYears = [];
            while (minYear < maxYear) {
                arrYears.push(minYear);
                minYear++;
            }
            this.setState({years: arrYears});
            console.log(years);
        }
        catch(e) {
            console.log('Submit Failed', e);
        }
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();

        
    }
    const handleComplete = () => {
        console.log("Form completed!");
        // Handle form completion logic here
      };

    const tabChanged = ({
        prevIndex,
        nextIndex,
      }: {
        prevIndex: number;
        nextIndex: number;
      }) => {
        console.log("prevIndex", prevIndex);
        console.log("nextIndex", nextIndex);
      };
    return (
        <div>
            <FormWizard shape="circle" color="#1890ff" onComplete={handleComplete} onTabChange={tabChanged}>
                <FormWizard.TabContent title="Vehicle Information" icon="ti-car">
                    <Row>
                        <Col sm={12}>
                            <h1>Add All Vehicles</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} lg={{span: 6, offset: 3}}>
                            <div style={{textAlign: 'left'}}>
                                <Form.Label>Year</Form.Label>
                                <Form.Control as="select" onChange={handleComplete} className="form-select">
                                    <option value=''></option>
                                    {/* {this.state.agencies.filter(i => i.inactive == false).map(a => 
                                        <option key={a.id} value={a.id}>{a.name}</option>
                                    )} */}
                                </Form.Control>
                                <Form.Label>Make</Form.Label>
                                <Form.Control as="select" onChange={handleComplete} className="form-select">
                                    <option value=''></option>
                                    {/* {this.state.agencies.filter(i => i.inactive == false).map(a => 
                                        <option key={a.id} value={a.id}>{a.name}</option>
                                    )} */}
                                </Form.Control>
                                <Form.Label>Model</Form.Label>
                                <Form.Control as="select" onChange={handleComplete} className="form-select">
                                    <option value=''></option>
                                    {/* {this.state.agencies.filter(i => i.inactive == false).map(a => 
                                        <option key={a.id} value={a.id}>{a.name}</option>
                                    )} */}
                                </Form.Control>
                            </div>
                        </Col>
                    </Row>
                </FormWizard.TabContent>
                <FormWizard.TabContent title="Additional Info" icon="ti-settings">
                    <h1>Second Tab</h1>
                    <p>Some content for the second tab</p>
                </FormWizard.TabContent>
                <FormWizard.TabContent title="Last step" icon="ti-check">
                    <h1>Last Tab</h1>
                    <p>Some content for the last tab</p>
                </FormWizard.TabContent>
            </FormWizard>
            {/* add style */}
            <style>{`@import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");`}</style>
        </div>
    )
};

export default AutoQuoteForm;